<template>
    <footer class="footer"></footer>
</template>

<script>
export default {
    name: 'CustomFooter'
}
</script>

<style lang="scss"></style>
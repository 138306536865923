<template>
  <section class="home">
    <div class="home--wrapper">
      <div class="home--wrapper__logo">
        <img class src="/general/logo.png" alt="Logo" />
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'home'
}
</script>

<style lang="scss">
.home {
  @apply h-full w-full flex justify-center items-center;
  background: #f7eee966;
  &--wrapper {
    @apply grid gap-y-6;
    &__logo {
      img {
        max-height: 170px;
      }
    }
  }
}
</style>
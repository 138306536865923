<template>
    <header></header>
</template>

<script>
export default {
    name: 'CustomMenu'
}
</script>

<style lang="scss"></style>